<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <!--<ion-content :fullscreen="true" :style="'--background: #fff url(\'' + selectedClub + '\') no-repeat center center / cover;'">-->
    <ion-content :fullscreen="true">
      <section v-if="isLoggedIn">
        <!--<ion-item color="warning" @click="goToProfile()" v-if="!isSuccessfullBraintreePaypalAuthorized && !isSuccessfullBraintreeCreditCardAuthorized && !getStatisticsLoading">
          <ion-label class="noselect ion-text-wrap">{{ $t('noPaymentMethod') }}</ion-label>
        </ion-item>-->
        <ion-grid>
          <ion-row class="ion-align-items-stretch">
            <ion-col  v-if="selectedCard && selectedBallmachine && selectedCard.ballmachines.length > 0" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title v-if="selectedCard && !selectedCard.ballmachines.find(f => f.name.includes('Putting'))">{{ $t('ballausgabe') }}</ion-card-title>
                  <ion-card-title v-if="selectedCard && selectedCard.ballmachines.find(f => f.name.includes('Putting'))">{{ $t('ballausgabeputting') }}</ion-card-title>
                  <ion-item lines="none">
                    <ion-select interface="popover" v-model="selectedBallmachine">
                      <ion-select-option v-for="(item, index) in selectedCard.ballmachines" :key="index" :value="item">{{ item.name }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                  <ion-item v-if="selectedCard && selectedBallmachine">
                    <ion-button v-if="selectedBallmachine && !selectedBallmachine.name.includes('Putting')" @click="requestAppButton(0)">{{ $t('getBalls') }}</ion-button>
                    <ion-button v-if="selectedBallmachine && selectedBallmachine.name.includes('Putting')" @click="requestAppButton(0)">{{ $t('getPutting') }}</ion-button>
                  </ion-item>
                  <ion-item v-if="counting" lines="full">
                    <vue-countdown ref="ballref" slot="start" :auto-start="true" @progress="checkTime" @end="checkEnd" :time="waitTime" v-slot="{ seconds }">
                      {{ $t('ballmachineTimerRunning') }}  <ion-label :color="clockColor">{{ seconds }} {{ $t('seconds') }}</ion-label>
                    </vue-countdown>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="selectedCard && cards.filter(f => f.ballmachines.length > 0).length > 1">
                    {{ $t('#') }}
                    <ion-select interface="popover" v-model="selectedCard">
                      <ion-select-option v-for="(item, index) in cards.filter(f => f.ballmachines.length > 0)" :key="index" :value="item">{{ item.name }} {{ item.uid }}</ion-select-option>          
                    </ion-select>
                    <ion-button fill="clear" @click="showHistory()">
                      <ion-icon style="zoom: 1.4;" color="primary" :ios="listOutline" :md="listOutline"></ion-icon>
                    </ion-button>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col  v-if="selectedCheckInCard && selectedCheckIn && selectedCheckInCard.checkins.length > 0" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title>{{ $t('checkin') }}</ion-card-title>
                  <ion-item lines="none">
                    <ion-select interface="popover" v-model="selectedCheckIn">
                      <ion-select-option v-for="(item, index) in selectedCheckInCard.checkins" :key="index" :value="item">{{ item.name }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                  <ion-item v-if="selectedCheckInCard && selectedCheckIn">
                    <ion-button v-if="selectedCheckInCard" @click="requestAppButton(1)">{{ $t('getCheckIn') }}</ion-button>
                  </ion-item>
                  <ion-item v-if="countingCheckIn" lines="full">
                    <vue-countdown ref="checkinref" slot="start" :auto-start="true" @progress="checkTimeCheckIn" @end="checkEndCheckIn" :time="waitTime" v-slot="{ seconds }">
                      {{ $t('checkInTimerRunning') }}  <ion-label :color="clockColor">{{ seconds }} {{ $t('seconds') }}</ion-label>
                    </vue-countdown>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="selectedCheckInCard && cards.filter(f => f.checkins.length > 0).length > 1">
                    {{ $t('#') }}
                    <ion-select interface="popover" v-model="selectedCheckInCard">
                      <ion-select-option v-for="(item, index) in cards.filter(f => f.checkins.length > 0)" :key="index" :value="item">{{ item.name }} {{ item.uid }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col  v-if="selectedDoorCard && selectedDoor && selectedDoorCard.doors.length > 0" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title>{{ $t('dooropen') }}</ion-card-title>
                  <ion-item lines="none">
                    <ion-select interface="popover" v-model="selectedDoor">
                      <ion-select-option v-for="(item, index) in selectedDoorCard.doors" :key="index" :value="item">{{ item.name }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                  <ion-item v-if="selectedDoorCard && selectedDoor">
                    <ion-button v-if="selectedDoorCard" @click="requestAppButton(3)">{{ $t('getDoor') }}</ion-button>
                  </ion-item>
                  <ion-item v-if="countingDoor" lines="full">
                    <vue-countdown ref="doorref" slot="start" :auto-start="true" @progress="checkTimeDoor" @end="checkEndDoor" :time="waitTime" v-slot="{ seconds }">
                      {{ $t('doorTimerRunning') }}  <ion-label :color="clockColor">{{ seconds }} {{ $t('seconds') }}</ion-label>
                    </vue-countdown>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="selectedDoorCard && cards.filter(f => f.doors.length > 0).length > 1">
                    {{ $t('#') }}
                    <ion-select interface="popover" v-model="selectedDoorCard">
                      <ion-select-option v-for="(item, index) in cards.filter(f => f.doors.length > 0)" :key="index" :value="item">{{ item.name }} {{ item.uid }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col  v-if="selectedBookingCard && selectedBooking && selectedBookingCard.bookings.length > 0" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title>{{ $t('turnon') }}</ion-card-title>
                  <ion-item lines="none">
                    <ion-select interface="popover" v-model="selectedBooking">
                      <ion-select-option v-for="(item, index) in selectedBookingCard.bookings" :key="index" :value="item">{{ item.name }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                  <ion-item v-if="selectedBookingCard && selectedBooking">
                    <ion-button v-if="selectedBookingCard" @click="requestAppButton(2)">{{ $t('getBooking') }}</ion-button>
                  </ion-item>
                  <ion-item v-if="countingBooking" lines="full">
                    <vue-countdown ref="bookingref" slot="start" :auto-start="true" @progress="checkTimeBooking" @end="checkEndBooking" :time="waitTime" v-slot="{ seconds }">
                      {{ $t('bookingTimerRunning') }}  <ion-label :color="clockColor">{{ seconds }} {{ $t('seconds') }}</ion-label>
                    </vue-countdown>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="selectedBookingCard && cards.filter(f => f.bookings.length > 0).length > 1">
                    {{ $t('#') }}
                    <ion-select interface="popover" v-model="selectedBookingCard">
                      <ion-select-option v-for="(item, index) in cards.filter(f => f.bookings.length > 0)" :key="index" :value="item">{{ item.name }} {{ item.uid }}</ion-select-option>          
                    </ion-select>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-stretch">
            <ion-col v-for="(stat, index) in statisticsToUse" :key="index" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card v-if="!stat.showContent">
                <ion-card-header>
                  <!--<ion-item :style="'--background: rgba(255,255,255,0)'" >-->
                  <ion-item>
                    <ion-icon @click="refresh()" style="zoom: 1.0" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
                    <ion-icon v-if="stat.details.length > 0" @click="stat.showContent= !stat.showContent" :ios="chevronDownOutline" :md="chevronDownOutline" class="ion-float-right"></ion-icon>
                    <ion-card-title @click="stat.details.length > 0 ? stat.showContent= !stat.showContent : stat.showContent=stat.showContent">{{ $t(stat.name) }}</ion-card-title>
                    <ion-icon @click="doClickStat(stat)" style="zoom: 1.0" :md="chevronForwardCircleOutline" :ios="chevronForwardCircleOutline"></ion-icon>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                    <apexchart type="bar" height="200" :options="stat.chartOptions" :series="stat.series"></apexchart>
                </ion-card-content>
              </ion-card>
              <ion-card v-if="stat.showContent">
                <ion-card-header>
                  <ion-item>
                    <ion-icon @click="refresh()" style="zoom: 1.0" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
                    <ion-icon @click="stat.showContent= !stat.showContent" :ios="chevronUpOutline" :md="chevronUpOutline" class="ion-float-right"></ion-icon>
                    <ion-card-title @click="stat.showContent= !stat.showContent">{{ $t(stat.name) }}</ion-card-title>
                    <ion-icon @click="doClickStat(stat)" style="zoom: 1.0" :md="chevronForwardCircleOutline" :ios="chevronForwardCircleOutline"></ion-icon>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-for="(item, index) in stat.details" :key="index" :value="item.id">
                    <ion-label class="ion-text-wrap">{{ item.name  }}</ion-label>
                    <ion-icon v-if="item.canDelete" slot="end"  @click="deleteItem(stat, item)" color="dangerlight" :ios="trashOutline" :md="trashOutline" class="ion-float-right"></ion-icon>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
      <section v-else>
        <ion-grid  v-if="enabled">
          <ion-row class="ion-align-items-stretch">
            <ion-col size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title> {{ $t("functions") }} </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="!isLoggedIn && appPages.appPages" v-for="(p, i) in appPages.appPages.filter(f => f.title != 'marshalls')" :key="i" lines="full" @click="doClick(p, appPages.appPages)" >
                    <ion-icon color="primary" style="margin-right: 5px;" :ios="p.icon" :md="p.icon"></ion-icon>
                    <ion-label :class="p.requiresLogin ? 'ion-text-wrap' : 'noselect ion-text-wrap clickable'" color="primary">{{ $t('home' + p.title) }}</ion-label>
                    <ion-icon slot="end" color="success" :ios="checkmarkDoneOutline" :md="checkmarkDoneOutline"></ion-icon>
                  </ion-item>
                  <ion-item v-if="!isLoggedIn" lines="full">
                    <ion-icon color="primary" style="margin-right: 5px;" :ios="documentOutline" :md="documentOutline"></ion-icon>
                    <ion-label class="ion-text-wrap" color="primary">{{ $t('homeMemberdata') }}</ion-label>
                    <ion-icon slot="end" color="success" :ios="checkmarkDoneOutline" :md="checkmarkDoneOutline"></ion-icon>
                  </ion-item>
                  <!-- <ion-item v-if="!isLoggedIn" lines="full">
                    <ion-icon color="primary" style="margin-right: 5px;" :ios="cashOutline" :md="cashOutline"></ion-icon>
                    <ion-label class="ion-text-wrap" color="primary">{{ $t('homePayment') }}</ion-label>
                    <ion-icon slot="end" color="success" :ios="checkmarkDoneOutline" :md="checkmarkDoneOutline"></ion-icon>
                  </ion-item> -->
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card>
                <ion-card-header>
                  <ion-card-title v-if="clubInfo.clubInfo"> {{ clubInfo.clubInfo.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-item v-if="!isLoggedIn" lines="none">
                    <ion-icon color="primary" style="margin-right: 5px;" :ios="personOutline" :md="personOutline"></ion-icon>
                    <ion-label class="ion-text-wrap" color="primary">{{ $t('homeMember') }}</ion-label>
                  </ion-item>
                  <ion-item v-if="!isLoggedIn" lines="full" @click="login()">
                    <ion-icon slot="start" color="success" :ios="logInOutline" :md="logInOutline"></ion-icon>
                    <ion-label class="clickable" color="success">{{ $t('login') }}</ion-label>
                  </ion-item>

                  <ion-item v-if="!isLoggedIn && clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canRegister" lines="none">
                    <ion-icon color="primary" style="margin-right: 5px;" :ios="personAddOutline" :md="personAddOutline"></ion-icon>
                    <ion-label class="ion-text-wrap" color="primary">{{ $t('homeNewCustomer') }}</ion-label>
                  </ion-item>
                  <ion-item v-if="!isLoggedIn && clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canRegister" lines="full" @click="openRegister()">
                    <ion-icon slot="start" color="warning" :ios="createOutline" :md="createOutline"></ion-icon>
                    <ion-label class="clickable" color="warning">{{ $t('register') }} </ion-label>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>

          <ion-card v-if="!enabled" class="cardconteudo ">
            <ion-card-header>
              <ion-img style="" :src="logocio" />
            </ion-card-header>
            <ion-card-content class="contentclass">
              <ion-list lines="none">
                <ion-list-header>{{ $t('mobileScreen1') }}.</ion-list-header>
              <ion-item class="cardconteudo">
                <ion-label style="font-size: 16px;" class="ion-text-wrap"><strong>{{ $t('teetimes') }}. {{ $t('livescorings') }}. {{ $t('homecompetitions') }}. {{ $t('mobileScreen2') }}. {{ $t('mobileScreen3') }}. {{ $t('mobileScreen4') }}. {{ $t('mobileScreen5') }}. {{ $t('friends') }}.</strong></ion-label>
              </ion-item>
              </ion-list>
              <ion-searchbar class="cardconteudo" :placeholder="$t('searchcompanies')" @ionBlur="findRecents(false)" @ionFocus="findRecents(true)" @ionClear="findOrganization('')" @input="findOrganization($event.target.value)">
              </ion-searchbar>
              <ion-list class="cardconteudo" v-if="organizationsFound && organizationsFound.length > 0">
                <ion-list-header>
                  {{ isClosest ? $t('closestClub') : $t('searchresult') }}
                </ion-list-header>
                <ion-item class="cardconteudo" v-for="(organization, i) in organizationsFound" :key="i" @click="addCompany(organization)">
                  <ion-avatar @contextmenu.prevent="">
                    <img :src="organization.imageSmall">
                  </ion-avatar>
                    <ion-label style="margin-left: 5px">
                      <h2>{{ organization.nameShort }}</h2>
                      <p>{{ organization.descriptionShort }}</p>
                    </ion-label>
                </ion-item>
              </ion-list>

              <ion-list class="cardconteudo" v-if="recentsFound && recentsFound.length > 0">
              <ion-list-header>
                {{ $t('history') }}
              </ion-list-header>
              <ion-item v-for="(recent, i) in recentsFound" :key="i">
                  <ion-avatar @contextmenu.prevent="" @click="selectCompany(recent)">
                    <img :src="recent.imageSmall">
                  </ion-avatar>
                    <ion-label style="margin-left: 5px" @click="selectCompany(recent)">
                      <h2>{{ recent.nameShort }}</h2>
                      <p>{{ recent.descriptionShort }}</p>
                    </ion-label>
                    <ion-icon @click="deleteRecent(recent)" color="danger" slot="end" :ios="trashOutline" :md="trashOutline"></ion-icon>
                </ion-item>
              </ion-list>
          </ion-card-content>
        </ion-card>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, alertController, toastController, modalController,
IonCard, IonCardContent, IonCardTitle, IonImg, IonCardHeader, IonGrid, IonRow, IonCol, IonIcon, IonItem, loadingController, isPlatform,
IonSearchbar, IonList, IonListHeader, IonAvatar, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref, onMounted, watch, computed } from 'vue'
import translate from '@/plugins/translate';
import dayjs from 'dayjs';
import { useQuery, onResult, useResult, useMutation } from '@vue/apollo-composable'
import getStatisticsQueryFile from '../graphql/getStatistics.query.gql'
import findOrganizationQueryFile from '../graphql/findOrganization.query.gql'
import { chevronDownOutline, chevronUpOutline, trashOutline, refreshOutline, logInOutline, createOutline, checkmarkDoneOutline,
golfOutline, peopleOutline, peopleCircleOutline, schoolOutline, reorderFourOutline, medalOutline, fastFoodOutline,
documentOutline, pencilOutline, cashOutline,personOutline,checkmarkOutline, mailOutline, personAddOutline, helpOutline,
listOutline, timeOutline, listCircleOutline, chevronForwardCircleOutline  } from 'ionicons/icons';
import deleteBookingTypeBookingMutationFile from '../graphql/deleteBooking.mutation.gql'
import deleteProBookingMutationFile from '../graphql/deleteProBooking.mutation.gql'
import unregisterCourseMutationFile from '../graphql/unregisterCourse.mutation.gql'
import unregisterTournamentMutationFile from '../graphql/unregisterTournament.mutation.gql'
import deleteRangeBookingMutationFile from '../graphql/deleteRangeBooking.mutation.gql'
import deleteWellnessBookingMutationFile from '../graphql/deleteWellnessBooking.mutation.gql'
import deleteTrackmanBookingMutationFile from '../graphql/deleteTrackmanBooking.mutation.gql'
import deleteIndoorBookingMutationFile from '../graphql/deleteIndoorBooking.mutation.gql'
import logoutUserMutationFile from '../graphql/logoutUser.mutation.gql'
import requestAppButtonMutationFile from '../graphql/requestAppButton.mutation.gql'
import checkInMutationFile from '../graphql/checkIn.mutation.gql'
import getClosestClubMutationFile from '../graphql/getClosestClub.mutation.gql'
import CardHistoryDialog from './dialogs/CardHistoryDialog'
import { getClubInfo, getAppPages } from '@/composition'
import LoginDialog from './Login'
import RegisterDialog from './Register'
import store from '../main'
import Storage from "../plugins/storage.js";
import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle, IonSelect, IonSelectOption,
    IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonItem, IonSearchbar, IonList, IonListHeader, IonAvatar,
    IonButton, IonLabel, IonCard, IonCardContent, IonCardTitle, IonImg, IonCardHeader
  },
  data() {
    return {
      logocio: require('@/assets/images/logo/logocio.png'),
    }
  },
  computed: {
      clubs : function(){ return JSON.parse(this.$store.getters.clubs)},
/*      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.getStatisticsRefetch()
        }
        return this.$store.getters.isLoggedIn
      },*/
      UserData : function(){ return this.$store.getters.UserData},
      selectedClub : function(){ return this.$store.getters.selectedClub},
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
  },
  setup() {
    function calcCrow(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }

    let selectedCard = ref()
    let selectedCheckInCard = ref()
    let selectedDoorCard = ref()
    let selectedBookingCard = ref()
    let selectedBallmachine = ref()
    let selectedCheckIn = ref()
    let selectedDoor = ref()
    let selectedBooking = ref()
    let counting = ref(false)
    let countingCheckIn = ref(false)
    let countingDoor = ref(false)
    let countingBooking = ref(false)
    let clockColor = ref('success')
    let waitTime = ref(15 * 1000)
    let loadingSpinner = null
    const clubInfo = ref(getClubInfo())
    const appPages = ref(getAppPages())
    const enabled = computed(() => store.getters.httpLink != 'https://backend-cio-online-frontend-mobile.smrtp.net')
    //const enabled = ref(false)
    const recentsFound = ref([])
    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 
    let statisticsToUse = ref([])
    let ismounted = ref(false)
    let isClosest = ref(false)

    const series = [{ name: '', data: [0,0,0,0,0,0,0,0,0,0,0,0] }]
    const chartOptions = {
      chart: { height: 200, type: 'bar', },
      plotOptions: { bar: { borderRadius: 10, dataLabels: { position: 'top', // top, center, bottom
      },} },
      dataLabels: { enabled: true,
        formatter: function (val) {
          return val + "";
        }, offsetY: -20, style: { fontSize: '12px', colors: ["#304758"] } },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", translate("May"), "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", translate("Dec")],
        position: 'top', axisBorder: { show: false }, axisTicks: { show: false },
        crosshairs: { fill: { type: 'gradient', gradient: { colorFrom: '#D8E3F0', colorTo: '#BED1E6', stops: [0, 100], opacityFrom: 0.4, opacityTo: 0.5, } } }, tooltip: { enabled: true, } },
      yaxis: { axisBorder: { show: false }, axisTicks: { show: false, }, labels: { show: false,
          formatter: function (val) {
            return val + "";
          } }},
      title: { text: '', floating: true, offsetY: 180, align: 'center', style: { color: '#444' } }
    }

    const { refetch: findOrganizationRefetch, result: findOrganizationResult, query: findOrganizationQuery } = useQuery(findOrganizationQueryFile, () => ({ searchTerm: '' }), () => ({ fetchPolicy: 'no-cache', enabled: !enabled.value }))

    let organizationsFound = useResult(findOrganizationResult, null, data => data.findOrganization.organizations)

    const {onResult, refetch: getStatisticsRefetch, result: getStatisticsResult, loading: getStatisticsLoading } = useQuery(getStatisticsQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache', enabled: enabled.value && isLoggedIn.value }) )
    const statisticsFound = useResult(getStatisticsResult, null, data => data.getStatistics.statistics)
    const cards = useResult(getStatisticsResult, null, data => data.getStatistics.cards)
    const longitude = useResult(getStatisticsResult, null, data => data.getStatistics.longitude)
    const latitude = useResult(getStatisticsResult, null, data => data.getStatistics.latitude)
    const canCheckIn = useResult(getStatisticsResult, null, data => data.getStatistics.canCheckIn)

    const { mutate: checkInMutation } = useMutation(checkInMutationFile, { fetchPolicy: 'no-cache' });

    onResult(queryResult => {

      if (queryResult.data && queryResult.data.getStatistics && queryResult.data.getStatistics.statistics) {
        let stats = []
        statisticsToUse.value = []
        for (const stat of queryResult.data.getStatistics.statistics) {
          let statToAdd = new Object()
          statToAdd.name = stat.name
          statToAdd.chartOptions = JSON.parse(JSON.stringify(chartOptions))
          statToAdd.data = stat.data
          statToAdd.details = stat.details
          statToAdd.chartOptions.title.text = (translate(stat.name) + ' ' + dayjs().format('YYYY'))
          statToAdd.showContent = stat.details.length > 0 ? true : false
          statToAdd.series = [{ name: translate(stat.name), data: stat.data }]
          stats.push(statToAdd)
        }
        statisticsToUse.value = stats
        //statisticsFound.value = queryResult.data.getStatistics.statistics
      }
      if (queryResult.data && queryResult.data.getStatistics && queryResult.data.getStatistics.cards && queryResult.data.getStatistics.cards.length > 0) {
        selectedCard.value = queryResult.data.getStatistics.cards[0]
        selectedCheckInCard.value = queryResult.data.getStatistics.cards[0]
        selectedBookingCard.value = queryResult.data.getStatistics.cards[0]
        selectedDoorCard.value = queryResult.data.getStatistics.cards[0]
        if (selectedCard.value.ballmachines.length > 0)
          selectedBallmachine.value = selectedCard.value.ballmachines[0]
        if (selectedCheckInCard.value.checkins.length > 0)
          selectedCheckIn.value = selectedCheckInCard.value.checkins[0]
        if (selectedBookingCard.value.bookings.length > 0)
          selectedBooking.value = selectedBookingCard.value.bookings[0]
        if (selectedDoorCard.value.doors.length > 0)
          selectedDoor.value = selectedDoorCard.value.doors[0]
      }
    })

    const { mutate: deleteBookingTypeBookingMutation } = useMutation(deleteBookingTypeBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteProBookingMutation } = useMutation(deleteProBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: unregisterCourseMutation } = useMutation(unregisterCourseMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: unregisterTournamentMutation } = useMutation(unregisterTournamentMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteRangeBookingMutation } = useMutation(deleteRangeBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteWellnessBookingMutation } = useMutation(deleteWellnessBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteTrackmanBookingMutation } = useMutation(deleteTrackmanBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteIndoorBookingMutation } = useMutation(deleteIndoorBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: logoutUserMutation } = useMutation(logoutUserMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestAppButtonMutation } = useMutation(requestAppButtonMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: getClosestClubMutation } = useMutation(getClosestClubMutationFile, { fetchPolicy: 'no-cache' });

    if (!enabled.value) {
      Geolocation.getCurrentPosition().then(res => {
        getClosestClubMutation({ lat: res.coords.latitude, long: res.coords.longitude}).then(res => {
          if (res.data.getClosestClub.organizations.length > 0) {
            isClosest.value = true
            findOrganizationRefetch({  searchTerm: res.data.getClosestClub.organizations[0].name })
          }
        })
      }).catch(err => { })
    }

    watch(canCheckIn,
      (value, prevId) => {
        if (value == true) {
          if (isLoggedIn.value && canCheckIn.value) {
            Geolocation.getCurrentPosition().then(res => {
              if (calcCrow(res.coords.latitude, res.coords.longitude, latitude.value, longitude.value) < 0.5) {
                alertController.create({
                  header: translate('checkIn'),
                  message: translate('checkInText'),
                  buttons: [
                    {
                      text: translate('no'),
                    },
                    {
                      text: translate('yes'),
                      handler: () => {
                        checkInMutation()
                      }
                    }
                    ] }).then(res => { res.present() })
              }

            }).catch(err => { })
            
          }
        }
      }
    )

    onMounted(() => {
      //if (isPlatform('ios' ) || isPlatform('android'))
      ismounted.value = true
      //setTimeout(() => { getStatisticsRefetch() }, 1500)
    })

    if (enabled.value)
      loadingController.create().then(res => {
        loadingSpinner = res
        loadingSpinner.present().then(() => { 
          if (!getStatisticsLoading.value) 
            loadingSpinner.dismiss() 
          })
      }).catch(err => { })

    watch(getStatisticsLoading, (getStatisticsLoading, prevLoading) => {
      if (ismounted.value && getStatisticsLoading)
        return
      if (!getStatisticsLoading && loadingSpinner != null)
      {
        loadingSpinner.dismiss()
        return
      }
      else if (getStatisticsLoading) {
        if (loadingSpinner)
          loadingSpinner.dismiss()
        
        loadingController.create().then(res => {
          loadingSpinner = res
          loadingSpinner.present().then(() => { 
              if (getStatisticsLoading == false)
              loadingSpinner.dismiss() 
          })
        }).catch(err => { })
      }
    })

    return {
      getStatisticsRefetch, clubInfo, enabled, organizationsFound, recentsFound, findOrganizationRefetch,
      chevronDownOutline, chevronUpOutline, trashOutline, deleteBookingTypeBookingMutation, deleteProBookingMutation, unregisterCourseMutation, unregisterTournamentMutation, deleteRangeBookingMutation, deleteIndoorBookingMutation, deleteWellnessBookingMutation, deleteTrackmanBookingMutation, refreshOutline,  statisticsFound, logInOutline, createOutline, checkmarkDoneOutline, logoutUserMutation, isLoggedIn, cards, countingCheckIn, countingBooking, countingDoor,
      golfOutline, peopleOutline, peopleCircleOutline, schoolOutline, reorderFourOutline, medalOutline, fastFoodOutline, documentOutline,
      pencilOutline, cashOutline, personOutline, checkmarkOutline, mailOutline, personAddOutline, helpOutline, appPages,
      selectedCard, selectedCheckInCard, selectedBookingCard, selectedBallmachine, selectedCheckIn, selectedBooking, waitTime, counting, clockColor, listOutline, requestAppButtonMutation, timeOutline, statisticsToUse, ismounted, getStatisticsLoading, selectedDoor, selectedDoorCard, isClosest, listCircleOutline, chevronForwardCircleOutline
    }
  },
  methods: {
    async showHistory() {
      const modal = await modalController
        .create({
          component: CardHistoryDialog,
          componentProps: {
            propsData: {
              selectedCard: this.selectedCard,
            }
          },
        })
      return modal.present();
    },
    checkEnd: function () {
      if (this.counting)
        this.counting = false
    },    
    checkEndCheckIn: function () {
      if (this.countingCheckIn)
        this.countingCheckIn = false
    },    
    checkEndDoor: function () {
      if (this.countingDoor)
        this.countingDoor = false
    },    
    checkEndBooking: function () {
      if (this.countingBooking)
        this.countingBooking = false
    },    
    checkTime: function (data) {
      if (data.seconds <= 1) {
        this.clockColor = 'success'
        this.counting = false
      }
      if (data.seconds <= 5)
        this.clockColor = 'danger'
      else if (data.seconds <= 10)
        this.clockColor = 'warning'
      else
        this.clockColor = 'success'
    },    
    checkTimeCheckIn: function (data) {
      if (data.seconds <= 1) {
        this.clockColor = 'success'
        this.countingCheckIn = false
      }
      if (data.seconds <= 5)
        this.clockColor = 'danger'
      else if (data.seconds <= 10)
        this.clockColor = 'warning'
      else
        this.clockColor = 'success'
    },    
    checkTimeDoor: function (data) {
      if (data.seconds <= 1) {
        this.clockColor = 'success'
        this.countingDoor = false
      }
      if (data.seconds <= 5)
        this.clockColor = 'danger'
      else if (data.seconds <= 10)
        this.clockColor = 'warning'
      else
        this.clockColor = 'success'
    },    
    checkTimeBooking: function (data) {
      if (data.seconds <= 1) {
        this.clockColor = 'success'
        this.countingBooking = false
      }
      if (data.seconds <= 5)
        this.clockColor = 'danger'
      else if (data.seconds <= 10)
        this.clockColor = 'warning'
      else
        this.clockColor = 'success'
    },    
    async requestAppButton(type) {
      this.clockColor = 'success'
      let counting = this.counting
      let counterRef = this.$refs.ballref
      if (type == 1) {
        counting = this.countingCheckIn
        counterRef = this.$refs.checkinref
      }
      if (type == 2) {
        counting = this.countingBooking
        counterRef = this.$refs.bookingref
      }
      if (type == 3) {
        counting = this.countingDoor
        counterRef = this.$refs.doorref
      }

      let ccid = this.selectedBallmachine && this.selectedBallmachine.ccid ? this.selectedBallmachine.ccid : ''
      let uid = this.selectedCard.uid
      if (type == 1) {
        ccid = this.selectedCheckIn.ccid
        uid = this.selectedCheckInCard.uid
      }
      else if (type == 2) {
        ccid = this.selectedBooking.ccid
        uid = this.selectedBookingCard.uid
      }
      else if (type == 3) {
        ccid = this.selectedDoor.ccid
        uid = this.selectedDoorCard.uid
      }
      this.requestAppButtonMutation({ ccid: ccid, uid: uid })
      .then(res => {
        if (res.data.requestAppButton.success == true) {
          if (counting) {
            this.waitTime = res.data.requestAppButton.confirmWaitingTime * 1000
            counterRef.restart()
          }
          else {
            this.waitTime = res.data.requestAppButton.confirmWaitingTime * 1000
            if (type == 0) this.counting = true
            else if (type == 1) this.countingCheckIn = true
            else if (type == 2) this.countingBooking = true
            else if (type == 3) this.countingDoor = true
          }
        }
        else if (!counting) {
          toastController
            .create({
              message: translate(res.data.requestAppButton.errorCode),
              duration: 5000,
              color: 'danger',
            }).then(res => res.present())
            if (type == 0) this.counting = false
            else if (type == 1) this.countingCheckIn = false
            else if (type == 2) this.countingBooking = false
            else if (type == 3) this.countingDoor = false
        }
      })
      .catch(err => {
        if (!counting) {
          toastController
            .create({
              message: translate('appButtonError') + err,
              duration: 5000,
              color: 'danger',
            }).then(res => res.present())
            if (type == 0) this.counting = false
            else if (type == 1) this.countingCheckIn = false
            else if (type == 2) this.countingBooking = false
            else if (type == 3) this.countingDoor = false
       }
      }) 
    },
    async addCompany(orga) {
      /*if (orga.url != this.$store.getters.httpLink) {
        await this.logout(false)
        this.$store.dispatch('logout')
      }*/
      this.isClosest = false
      const clubs = this.$store.getters.clubs != null ? JSON.parse(this.$store.getters.clubs) : []
      const found = clubs.filter(e => e._id === orga._id).length > 0
      if (!found) {
        clubs.unshift(orga)
        await Storage.setItem('clubs', JSON.stringify(clubs))
        this.$store.dispatch('getClubs')
      }
      else if (clubs.findIndex(o => o._id == orga._id) != 0) {
        clubs.splice(clubs.findIndex(o => o._id == orga._id))
        clubs.unshift(orga)
        await Storage.setItem('clubs', JSON.stringify(clubs))
        this.$store.dispatch('getClubs')
      }
      await Storage.setItem('httpLink', orga.url)
      this.$store.dispatch('setHttpLink')
      this.$router.go()

    },
    async selectCompany(orga) {
      if (orga.url != this.$store.getters.httpLink) {
        await this.logout()
      }
      const clubs = this.$store.getters.clubs != null ? JSON.parse(this.$store.getters.clubs) : []
      if (clubs.findIndex(o => o._id == orga._id) != 0) {
        clubs.splice(clubs.findIndex(o => o._id == orga._id), 1)
        clubs.unshift(orga)
        await Storage.setItem('clubs', JSON.stringify(clubs))
        this.$store.dispatch('getClubs')
      }
      await Storage.setItem('httpLink', orga.url)
      this.$store.dispatch('setHttpLink')
      this.$router.go()
    },
    async deleteRecent(recent) {
      const clubs = this.$store.getters.clubs != null ? JSON.parse(this.$store.getters.clubs) : []
      clubs.splice(clubs.findIndex(o => o._id == recent._id), 1)
      await Storage.setItem('clubs', JSON.stringify(clubs))
      this.$store.dispatch('getClubs')
    },
    async logout() {
        const refreshToken = await Storage.getItem('refreshToken')
        this.logoutUserMutation({
          refreshToken: refreshToken
        })
        .then(res => {
          this.$store.dispatch('logout')
          this.$router.go()
        })
        .catch(err => {
        })
    },
    async findOrganization(searchTerm) {
      this.isClosest = false
      if (searchTerm.length > 0)
        this.recentsFound = []
      if (searchTerm.length >= 3) {
        this.findOrganizationRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findRecents(true)
        this.findOrganizationRefetch({ searchTerm: ''})
        //this.enable({ searchTerm })
      }
    },
    findRecents(value) {
      if (value)
        this.recentsFound = this.clubs
      else
        setTimeout(() => { this.recentsFound = [] }, 200)
      
    },
    goToProfile() {
      this.$router.push('/profile')
    },
    doClick(p, appPages) {
      if (!p.requiresLogin) {
        this.$router.push(p.url);
        this.$store.dispatch('setSelectedIndex', appPages.findIndex(page => page.title.toLowerCase() === p.title)+1)
      }
    },
    doClickStat(stat) {
      const p = this.appPages.appPages.find(f=>f.title == stat.name)
      if (p) {
        this.$router.push(p.url);
        this.$store.dispatch('setSelectedIndex', this.appPages.appPages.findIndex(page => page.title.toLowerCase() === p.title)+1)
      }
    },
    async openRegister() {
      const modal = await modalController
        .create({
          cssClass: this.isPlatform('mobile') ? '' : 'editBooking-modal',
          component: RegisterDialog,
        })
      return modal.present();
    },
    async login() {
      const modal = await modalController
        .create({
          component: LoginDialog,
        })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              if (this.$route.path.startsWith('/verify') || this.$route.path.startsWith('/reset'))
                this.$router.push('/home')
            }
          })
      return modal.present();
    },
    isPlatform(platform) {
      return isPlatform(platform)
    },
    refresh() {
      this.ismounted = false
      this.getStatisticsRefetch()
    },
    async deleteItem(stat, item) {
      this.ismounted = false
      const alert = await alertController
        .create({
          header: this.$t(stat.name),
          message: this.$t('delete') + ': ' + dayjs(item.datetime).format('DD.MM.YYYY HH:mm') + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                if (stat.name == "teetimes") {
                  this.deleteBookingTypeBookingMutation({ dateSlot: item.datetime, position: item.position, bookingTypeId: item.courseid.toString(), language: this.$store.getters.language})
                  .then(res => {
                      toastController
                        .create({
                          message: this.$t('bookingDeleted'),
                          duration: 3000,
                          color: 'warning'
                        }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                      toastController
                        .create({
                          message: this.$t('notSuccessful') + ': ' + err,
                          duration: 3000,
                          color: 'danger'
                        }).then(res => res.present())
                  }) 
                }
                else if (stat.name == 'pros' || stat.name == 'pro') {
                  this.deleteProBookingMutation({ date: item.datetime, proId: item.position, id: parseInt(item.id), language: this.$store.getters.language })
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'courses') {
                  this.unregisterCourseMutation({ id: parseInt(item.id), language: this.$store.getters.language})
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'competitions' || stat.name == 'events') {
                  this.unregisterTournamentMutation({ id: parseInt(item.id), addPlayers: '', language: this.$store.getters.language})
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'ranges') {
                  this.deleteRangeBookingMutation({ date: item.datetime, rangeId: item.position, id: parseInt(item.id), language: this.$store.getters.language })
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'wellnesses') {
                  this.deleteWellnessBookingMutation({ date: item.datetime, wellnessId: item.position, id: parseInt(item.id), language: this.$store.getters.language })
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'trackmans') {
                  this.deleteTrackmanBookingMutation({ date: item.datetime, trackmanId: item.position, id: parseInt(item.id), language: this.$store.getters.language })
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
                else if (stat.name == 'indoors') {
                  this.deleteIndoorBookingMutation({ date: item.datetime, indoorId: item.position, id: parseInt(item.id), language: this.$store.getters.language })
                  .then(res => {
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'warning'
                      }).then(res => res.present())
                      this.getStatisticsRefetch()
                  })
                  .catch(err => {
                    toastController
                      .create({
                        message: this.$t('deleteNotSuccessful' + err),
                        duration: 2000,
                        color: 'danger',
                      }).then(res => res.present())
                  })
                }
              },
            },
          ],
        });
      return alert.present();

    },
  }
});
</script>

<style scoped>

.contentclass {
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 0% 14%;
}
.clickable {
    cursor: pointer;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.editBooking-modal .modal-wrapper {
    width: 70%;
    height: 95%;
 }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>